@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');


html{
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #080C14;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* css marvel app */
.container {
  display: flex;
  flex-direction: column;
  padding: 0;
}

div {
  padding: 0;
}


/* gestion des titres flex */
h1, h2, h3, h4, h5, h6 {
  display: flex;
  color: white;
  padding: 0;
}

h3 {
  font-weight: 700;
}

a:hover {
  text-decoration: none;
}