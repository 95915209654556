/* gestion footer */
.row {
  justify-content: center;
  margin-top: 0.25rem;
}

.logo {
  background-image: url("../images/logo_marvel.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 10rem;
  height: 2.4rem;
  align-self: center;
  padding: 0;
  margin-bottom: 0.5rem;
}

.logoFooter {
  background-image: url("../images/logo_marvel.svg");
  background-repeat: no-repeat;
  background-size: contain;
  --largeurA: 6rem;
  --hauteurB: 2.5rem;
  width: calc(var(--largeurA) / 1.65);
  height: calc(var(--hauteurB) / 1.65);
  align-self: center;
}

.imgPage {
  display: flex;
  width: calc(17.5rem / 1.2);
}

#footerMargin {
  margin: 2rem 0;
}

/* gestion des liens dans le Col du footer */
.footerCol {
  display: inline-flex;
  justify-content: center;
  white-space: normal;
  padding: 0.05rem;
  color: #8197a4;
  font-size: 0.6rem;
  list-style: none;
}

.footerCol > a {
  color: #79b8f3;
  text-decoration: none;
}

.footerCol > a:hover {
  color: #90dffe;
  transition: 0.5s ease-in;
  text-decoration: none;
  cursor: pointer;
}

/* CSS Hero Carousel */
.carousel {
  width: 98%;
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;
  margin-bottom: 3rem;
}

.carousel-inner {
  border-radius: 10px;
  border: 1.5px solid #080c14;
}

.carousel-inner:hover {
  border: 1.5px solid white;
  cursor: pointer;
}

.carousel-indicators {
  margin-bottom: 0;
}

.carousel-control-prev,
.carousel-control-next {
  width: 5%;
  opacity: 0.8;
}

.imgCarousel {
  height: auto;
}

.carousel-indicators li {
  width: 10px;
  height: 3px;
}


/* media query */
@media (max-width: 1024px) {
  .logo {
    margin-left: calc(1rem / 1.2);
  }
 }



/* media query footer */
@media only screen and (max-width: 576px) {
  .logo {
    --largeurA: 6rem;
    --hauteurB: 2.5rem;
    width: calc(var(--largeurA) / 1.5);
    height: calc(var(--hauteurB) / 1.5);
  }
  .logoFooter {
    --largeurA: 6rem;
    --hauteurB: 2.5rem;
    width: calc(var(--largeurA) / 2.25);
    height: calc(var(--hauteurB) / 2.25);
  }

  .footerCol {
    font-size: calc(0.8rem / 1.5);
  }
}
