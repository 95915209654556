@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap);
/* nav-bar styles */
.nav-bar {
  background: #080c14;
  color: white;
  width: 100%;
  top: 0;
  left: 0;
  height: 100px;
  display: flex;
  align-items: center;
  padding: 5px;
}

.nav-icon {
  cursor: pointer;
  margin-left: 5px;
}

.nav-items{
  display: flex;
  margin: 0;
}

.nav-items a {
  align-items: center;
  margin: auto;
  color: white;
  text-decoration: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  padding: 0 0.5rem;
}

.nav-items a:hover,
.nav-items a:active {
  text-shadow: 1px 1px #ff0000;
  color: white;
  text-decoration: inherit;
  cursor: pointer;
}

.nav-space {
  flex: 1 1;
}

.nav-icon {
  margin-right: 2rem;
}

/* side-nav-bar styles */
.side-nav-bar {
  height: 100%;
  background: #080c14;
  width: 100%;
  position: fixed;
  z-index: 300;
  top: 0;
  left: 0;
  color: white;
  transform: translateX(-100%);
  transition: transform 0.4s;
}

.side-nav-bar{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.side-nav-bar.open {
  transform: translateX(0);
}

.side-nav-bar a {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 15px;
}

nav, .side-nav-bar a {
  color: white;
  font-size: 1.2em;
  text-transform: uppercase;
  text-decoration: none;
}

.side-nav-bar a:hover,
.side-nav-bar a:active {
  text-shadow: 1px 1px #ff0000;
  color: white;
  text-decoration: inherit;
  cursor: pointer;
}


/* SearchBar pour Mobile */
#searchBarMobile {
  width: 150px;
  padding: 5px;
  background: #0d111a;
  color: white;
  font-size: 1.2em;
  text-transform: uppercase;
  border: none;
  border-bottom: 1px solid white;
  margin-bottom: 20px;
  margin-top:50px;
  width: 50%;
  text-align: center;
}

#searchBarMobile:focus {
  outline: none;
  color: red;
}


/* Backdrop style */
.backdrop {
  height: 100%;
  width: 100%;
  background: #161a25;
  top: 0;
  left: 0;
  z-index: 200;
  position: fixed;
}

/* Media Query */
@media (max-width: 702px) {
  .nav-items {
    display: none;
  }

  #logo {
    height: 40px;
    width: auto;
  }
}

@media (min-width: 703px) {
  .nav-icon {
    display: none;
  }
}

/* gestion footer */
.row {
  justify-content: center;
  margin-top: 0.25rem;
}

.logo {
  background-image: url(/static/media/logo_marvel.278254a6.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 10rem;
  height: 2.4rem;
  align-self: center;
  padding: 0;
  margin-bottom: 0.5rem;
}

.logoFooter {
  background-image: url(/static/media/logo_marvel.278254a6.svg);
  background-repeat: no-repeat;
  background-size: contain;
  --largeurA: 6rem;
  --hauteurB: 2.5rem;
  width: calc(var(--largeurA) / 1.65);
  height: calc(var(--hauteurB) / 1.65);
  align-self: center;
}

.imgPage {
  display: flex;
  width: calc(17.5rem / 1.2);
}

#footerMargin {
  margin: 2rem 0;
}

/* gestion des liens dans le Col du footer */
.footerCol {
  display: inline-flex;
  justify-content: center;
  white-space: normal;
  padding: 0.05rem;
  color: #8197a4;
  font-size: 0.6rem;
  list-style: none;
}

.footerCol > a {
  color: #79b8f3;
  text-decoration: none;
}

.footerCol > a:hover {
  color: #90dffe;
  transition: 0.5s ease-in;
  text-decoration: none;
  cursor: pointer;
}

/* CSS Hero Carousel */
.carousel {
  width: 98%;
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;
  margin-bottom: 3rem;
}

.carousel-inner {
  border-radius: 10px;
  border: 1.5px solid #080c14;
}

.carousel-inner:hover {
  border: 1.5px solid white;
  cursor: pointer;
}

.carousel-indicators {
  margin-bottom: 0;
}

.carousel-control-prev,
.carousel-control-next {
  width: 5%;
  opacity: 0.8;
}

.imgCarousel {
  height: auto;
}

.carousel-indicators li {
  width: 10px;
  height: 3px;
}


/* media query */
@media (max-width: 1024px) {
  .logo {
    margin-left: calc(1rem / 1.2);
  }
 }



/* media query footer */
@media only screen and (max-width: 576px) {
  .logo {
    --largeurA: 6rem;
    --hauteurB: 2.5rem;
    width: calc(var(--largeurA) / 1.5);
    height: calc(var(--hauteurB) / 1.5);
  }
  .logoFooter {
    --largeurA: 6rem;
    --hauteurB: 2.5rem;
    width: calc(var(--largeurA) / 2.25);
    height: calc(var(--hauteurB) / 2.25);
  }

  .footerCol {
    font-size: calc(0.8rem / 1.5);
  }
}

html{
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #080C14;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* css marvel app */
.container {
  display: flex;
  flex-direction: column;
  padding: 0;
}

div {
  padding: 0;
}


/* gestion des titres flex */
h1, h2, h3, h4, h5, h6 {
  display: flex;
  color: white;
  padding: 0;
}

h3 {
  font-weight: 700;
}

a:hover {
  text-decoration: none;
}
/* Style Slider images */
.marvelCatImg {
  width: inherit;
  border-radius: 0.4rem;
}

.marveCatComics {
  margin-bottom: 0.5rem;
}

/*hover slide Character + comic page Home */
.transition {
  width: 100%;
  border-radius: 0.4rem;
  opacity: 0.6;
}

.marvelCatImg:hover {
  box-shadow: 0px 0px 0px 0.125rem #ffffff;
  transition: 0.5s linear !important;
  cursor: pointer;
}

.transition:hover {
  transform: scale(1.05);
  transition: 0.5s linear !important;
  opacity: 1;
  cursor: pointer;
}

/*hover page Character and Comics */
.transitionCharacter {
  width: 16rem;
  height: 16rem;
  border-radius: 0.4rem;
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.transitionCharacter:hover {
  box-shadow: 0px 0px 0px 0.125rem rgba(255, 255, 255, 1);
  transform: scale(1.05);
  transition: 0.5s linear !important;
  cursor: pointer;
}

/* Page Game */
.titleGame {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.imageGame {
  border-radius: 0.4rem 0.4rem 0 0;
}

.gameRules {
  padding: 1rem 10%;
  border-radius: 0 0 0.4rem 0.4rem;
}

.rules {
  font-size: 0.8em;
  text-align: center;
}

.space-para {
  margin-bottom: 2%;
  padding: 0 1rem;
}

.rulestitle {
  font-size: 1em;
}

.containerFightersChoosen {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.containerFightersHero {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.containerFightersEnemi {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.transitionGame {
  border-radius: 0.4rem;
  margin: 0 1rem;
  width: 85%;
}

.transitionGame:hover {
  box-shadow: 0px 0px 0px 0.125rem rgba(255, 255, 255, 1);
  transform: scale(1.05);
  transition: 0.5s linear !important;
  cursor: pointer;
}

.heroFight {
  width: calc(17.5rem / 1.2);
  border-radius: 0.4rem;
}

.heroCard {
  margin: 0.5rem 0 1.5rem 0;
}

.col {
  padding: 0 2rem;
}

.progressBar {
  width: 77%;
  align-self: center;
  bottom: 0.1rem;
}

.titleProgressBar {
  align-self: center !important;
  position: relative;
  margin-right: 0.8rem;
}

.characteristic {
  justify-content: space-between;
  margin: 0 0.85rem;
}

.characteristicBase {
  font-size: 0.8rem;
  margin-top: 3%;
  color: rgba(236, 29, 36, 0.65);
}

.rowProgressBar {
  justify-content: space-between;
  padding: 0 1rem;
  /*visibility: hidden;*/
}

/* Bande titre slider Character and pages Character */
.overlay {
  position: relative;
  bottom: 1.8rem;
  background: rgba(236, 29, 36, 0.65);
  width: inherit;
  border-radius: 0 0 0.4rem 0.4rem;
  opacity: 1;
  color: white;
  font-size: 0.7rem;
  padding: 0.5rem;
  text-align: center;
}

.overlayCharacter {
  position: relative;
  bottom: 2.15rem;
  background: rgba(236, 29, 36, 0.65);
  width: inherit;
  border-radius: 0 0 0.4rem 0.4rem;
  opacity: 1;
  color: white;
  font-size: 1rem;
  padding: 0.5rem;
  text-align: center;
}

/* carousel Slider Slick */
.carouselSlider {
  width: 100%;
  padding-top: 0.5rem;
}

.slick-track {
  left: -0.05rem;
}

.slick-list {
  padding-bottom: 0.1rem;
}

.slick-disabled {
  visibility: hidden;
}

.slick-prev,
.slick-next {
  color: transparent;
  position: absolute;
  z-index: 1;
  padding-bottom: 10px;
  outline: none;
  width: 3rem;
}

.slick-next:before {
  opacity: 0.75;
  content: URL(/static/media/next.d6d96028.svg);
}

.slick-prev:before {
  opacity: 0.75;
  content: url(/static/media/prev.efd9e52f.svg);
}

.slick-slide {
  margin-top: 1.75rem;
}

/*.slick-next {
  right: -0.75rem;
}*/

/* Titre slider and pages */
.titleSlider {
  font-size: 1.3rem;
  justify-content: center;
  margin-top: 0.5rem;
}

/* Pages Character and Comics */
.textCenter {
  justify-content: center;
  margin-top: 0;
  margin-bottom: 3rem;
  margin-left: 0;
  margin-right: 0;
}

/*définition des cards*/
.cardContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.heroesCard {
  width: 16rem;
  height: 16rem;
  border-radius: 0.4rem;
}

.comicsCard {
  width: 100%;
  border-radius: 0.4rem;
}

/* Personnalisation du composant Modal*/
.modal {
  width: 30%;
  top: 4%;
  left: 34%;
}

.modal-header {
  background: rgba(236, 29, 36, 1);
  border: 0;
  padding: 0.5rem 0.5rem;
}

.modal-title {
  color: white;
  font-size: 1rem;
}

.modal-content {
  width: 100%;
}

.modal-body {
  padding: 0;
}

.modal-footer {
  border: 0;
}

.modalText {
  padding: 1rem;
  font-size: 0.8rem;
}

a {
  text-decoration: none;
  color: #79b8f3;
}


.modalText > a:hover {
  text-decoration: none;
  
}

.imgModal {
  width: 100%;
  margin: 0;
}

.heroesImg {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modalFooter {
  display: flex;
  justify-content: center;
}

.btn {
  background-image: url(/static/media/btn_marvel.5a2c3062.svg);
  background-repeat: no-repeat;
  width: 10rem;
  height: 3rem;
  font-weight: 400;
  padding: 0.75rem 1.25rem 0 0;
  font-size: 1rem;
  color: white;
  margin: 0 0 0 1.25rem;
}

.btn:hover {
  background-image: url(/static/media/btn_marvel_dark.665cc9c6.svg);
  transition: 0.25s ease-in-out;
  color: rgb(86, 29, 36);
  cursor: pointer;
}

.btn-rule {
  background-image: url(/static/media/btn_marvel.5a2c3062.svg);
  background-repeat: no-repeat;
  width: 10rem;
  height: 3rem;
  font-weight: 400;
  padding: 0.75rem 1.25rem 0 1.85rem;
  font-size: 1rem;
  color: white;
  margin: 0 0 0 1.25rem;
}

.btn-rule:hover {
  background-image: url(/static/media/btn_marvel_dark.665cc9c6.svg);
  transition: 0.25s ease-in-out;
  color: rgb(86, 29, 36);
  cursor: pointer;
}

.btn-center {
  display: flex;
  justify-content: center;
  margin-bottom: 3%;
}

.btnComic {
  padding-top: 0.2rem;
}

.modalBody {
  display: flex;
}

/* CSS SearchBar */
.searchBar {
  margin-top: 30px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#searchInput {
  width: 80%;
  height: 50px;
  background-color: #080c14;
  border: 1px solid #151b2a;
  font-size: 1em;
  padding-left: 30px;
  color: white;
}

#searchInput::-webkit-input-placeholder {
  color: white;
}

#searchInput:-ms-input-placeholder {
  color: white;
}

#searchInput::placeholder {
  color: white;
}

#searchInput:focus {
  background-color: #151b2a;
  border: 1px solid #151b2a;
  outline: none;
  color: white;
  padding-left: 30px;
}

#searchButton {
  width: 20%;
  height: 50px;
  background-color: #151b2a;
  border: 1px solid #151b2a;
  font-size: 1em;
  color: white;
  cursor: pointer;
}

#searchButton:hover {
  background-color: #080c14;
  border: 1px solid white;
  cursor: pointer;
}

#noResult {
  color: white;
  font-size: 1em;
  text-align: center;
}

/* Media Query */
/* 375 411 540 680 768 1024 */
@media (max-width: 1024px) {
.title-page {
  margin-bottom: 0.5rem;
}

  .titleSlider {
    font-size: calc(1.3rem / 1.4);
  }

  .marvelCatImg {
    width: inherit;
  }

  .marveCatComics {
    margin-bottom: 0.1rem !important;
  }

  .overlay {
    font-size: calc(0.7rem / 1.3);
    padding: calc(0.5rem / 1.2);
    bottom: calc(2.15rem / 1.5);
  }

  /*hover page Character and Comics */
  .transitionCharacter {
    width: calc(900px / 5.5);
    height: calc(900px / 5.5);
    overflow: hidden;
  }

  /*définition des cards*/
  .heroesCard {
    height: inherit;
    padding-right: 50%;
  }

  /*hover slide Character + comic page Home */
  .transition {
    width: 100%;
    border-radius: 0.4rem;
    opacity: 0.6;
  }

  /* Bande titre slider Character and pages Character */
  .overlayCharacter {
    bottom: 1.8rem;
    font-size: 0.65rem;
    padding: 0.55rem;
  }

  #searchInput {
    width: 80%;
    height: 30px !important;
    background-color: #080c14;
    border: 1px solid #151b2a;
    font-size: 1em;
    padding-left: 30px;
    color: white;
  }

  #searchInput::-webkit-input-placeholder {
    color: white;
  }

  #searchInput:-ms-input-placeholder {
    color: white;
  }

  #searchInput::placeholder {
    color: white;
  }

  #searchInput:focus {
    background-color: #151b2a;
    border: 1px solid #151b2a;
    outline: none;
    color: white;
    padding-left: 30px;
  }

  #searchButton {
    width: 20%;
    height: 30px !important;
    background-color: #151b2a;
    border: 1px solid #151b2a;
    font-size: 1em;
    color: white;
    cursor: pointer;
  }

  #searchButton:hover {
    background-color: #080c14;
    border: 1px solid white;
    cursor: pointer;
  }

  /* image carousel game */
  .titleGame {
    display: flex;
    flex-direction: column;
    width: 98%;
    margin: 0 auto;
  }

  .characteristicBase {
    font-size: 0.65rem;
  }

  /* Personnalisation du composant Modal*/
  .modal {
    width: 50%;
    top: 4%;
    left: 30%;
  }

  .modal-body {
    padding: 0;
  }

  .modal-footer {
    border: 0;
    width: 100%;
  }

  
.modal-content {
  width: 85%;
}

  .modalText {
    padding: 1rem;
    font-size: 0.7rem;
    margin-bottom: 0.25rem;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
  }

  .space-para {
    font-size: 0.8rem;
  }

  .imgModal {
    width: 100%;
    margin: 0;
  }

  .heroesImg {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .button:hover {
    border: none;
    outline: none !important;
    transition: none !important;
  }

  .btn {
    background-image: url(/static/media/btn_marvel.5a2c3062.svg);
    background-repeat: no-repeat;
    width: calc(10rem / 1.4);
    height: calc(3rem / 1.4);
    padding: 0.3rem 0.9rem 0 0;
    font-size: 1rem;
    color: white;
    margin: 0 0.1rem 0 1rem;
  }

  .btn:hover {
    background-image: url(/static/media/btn_marvel_dark.665cc9c6.svg);
    color: rgb(86, 29, 36);
    outline: none;
  }

  .btn-rule {
    width: calc(10rem / 1.2);
    height: calc(3rem / 1.2);
    font-weight: 400;
    padding: 0.5rem 0 0 1.15rem;
    font-size: 1rem;
    color: white;
    margin: 0 0 0 1.25rem;
  }

  .btn-rule:hover {
    background-image: url(/static/media/btn_marvel_dark.665cc9c6.svg);
    transition: 0.25s ease-in-out;
    color: rgb(86, 29, 36);
  }

  .btn-center {
    display: flex;
    justify-content: center;
    margin-bottom: 3%;
  }

  .modalBody {
    display: flex;
  }

  /* carousel Slider Slick */
  .carouselSlider {
    width: 100%;
    padding-top: 0.5rem;
    left: 0.5rem;
  }

  .slick-list {
    padding-bottom: 0.1rem;
  }

  .slick-disabled {
    visibility: hidden;
  }

  .slick-prev {
    left: -1.1rem;
  }
  .slick-next {
    right: -0.1rem;
  }

  .slick-prev,
  .slick-next {
    visibility: hidden;
  }

  .slick-slide {
    padding: 0 !important;
    margin: 0 !important;
  }

  .heroFight {
    width: calc(17.5rem / 1.25);
  }

  .transitionGame {
    margin-top: 1rem;
    width: 90%;
  }
}

/* Small screen GAME page */
@media screen and (max-width: 770px) {
  .margin-card {
    margin-top: 1rem;
  }

  .marvelCatImg {
    width: inherit;
    border-radius: 0.4rem;
  }

  .marvelCatImg:hover {
    border: none;
  }

  #searchInput::-webkit-input-placeholder {
    font-size:0.75rem;
  }

  #searchInput:-ms-input-placeholder {
    font-size:0.75rem;
  }

  #searchInput::placeholder {
    font-size:0.75rem;
  }

  #searchInput {
    padding-left: 6px;

  }
  
  .modal {
    width: 75%;
    top: 4%;
    left: 18%;
  }

  .containerFightersChoosen {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .heroFight {
    width: calc(17.5rem / 2.25);
  }

  .imageGame {
    width: 100%;
  }

  .area {
    font-size: 2em;
  }

  .btnGame {
    width: 3.5rem;
    height: 1.8rem;
    padding: 0.1rem 0 0 0;
    font-size: 1rem;
    color: white;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 0;
    background-color: rgba(236, 29, 36, 1);
    border: 1px solid white;
    display: inline-block;
    font-weight: 700;
  }

  .btn:hover {
    background-image: none;
    transition: none;
    color: black;
    outline: none;
  }

  .gameRules {
    padding: 0.8rem 5%;
    margin: 0 auto;
    width: 100%;
  }

  .containerFightersHero {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
  
  .containerFightersEnemi {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .transitionGame {
    margin-top: 1rem;
    width: 80%;
  }

  .progressBar {
    width: 68%;
    align-self: center;
    bottom: 0.1rem;
  }

  .titleProgressBar {
    font-size: 95%;
    padding-top: 5%;
  }

  .characteristic {
    font-size: 85%;
    padding: 0 0.25rem;
  }

  /* Titre slider and pages */
  .titleSlider {
    font-size: 1rem;
    justify-content: center;
    margin-bottom: 0.25rem !important;
  }
}

.searchBar{
    margin-top: 30px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#searchInput{
    width: 80%;
    height: 50px;
    background-color: #080C14;
    border: 1px solid #151b2a;
    font-size: 1em;
    padding-left: 30px;
    color: white;
}

#searchInput::-webkit-input-placeholder { 
    color: white;
}

#searchInput:-ms-input-placeholder { 
    color: white;
}

#searchInput::placeholder { 
    color: white;
}

#searchInput:focus{
    background-color: #151b2a;
    border: 1px solid #151b2a;
    outline: none;
    color: white;
    padding-left: 30px
}

#searchButton{
    width: 20%;
    height: 50px;
    background-color: #151b2a;
    border: 1px solid #151b2a;
    font-size: 1em;
    color: white;
    cursor: pointer
    ;
}

#searchButton:hover{
    background-color:#080C14;
    border: 1px solid white;
    cursor: pointer;
}


#noResult{
    color: white;
    font-size: 1em;
    text-align: center;
}





/* GAME */


#choice{
    border: 1px solid white;
    width: 200px;
    height: 200px;
}

.choiceAllie{
    border: 1px solid white;
    width: 200px;
    height: 200px;
}

.choiceenemie{
    border: 1px solid white;
    width: 200px;
    height: 200px;
}

