/* nav-bar styles */
.nav-bar {
  background: #080c14;
  color: white;
  width: 100%;
  top: 0;
  left: 0;
  height: 100px;
  display: flex;
  align-items: center;
  padding: 5px;
}

.nav-icon {
  cursor: pointer;
  margin-left: 5px;
}

.nav-items{
  display: flex;
  margin: 0;
}

.nav-items a {
  align-items: center;
  margin: auto;
  color: white;
  text-decoration: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  padding: 0 0.5rem;
}

.nav-items a:hover,
.nav-items a:active {
  text-shadow: 1px 1px #ff0000;
  color: white;
  text-decoration: inherit;
  cursor: pointer;
}

.nav-space {
  flex: 1;
}

.nav-icon {
  margin-right: 2rem;
}

/* side-nav-bar styles */
.side-nav-bar {
  height: 100%;
  background: #080c14;
  width: 100%;
  position: fixed;
  z-index: 300;
  top: 0;
  left: 0;
  color: white;
  transform: translateX(-100%);
  transition: transform 0.4s;
}

.side-nav-bar{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.side-nav-bar.open {
  transform: translateX(0);
}

.side-nav-bar a {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 15px;
}

nav, .side-nav-bar a {
  color: white;
  font-size: 1.2em;
  text-transform: uppercase;
  text-decoration: none;
}

.side-nav-bar a:hover,
.side-nav-bar a:active {
  text-shadow: 1px 1px #ff0000;
  color: white;
  text-decoration: inherit;
  cursor: pointer;
}


/* SearchBar pour Mobile */
#searchBarMobile {
  width: 150px;
  padding: 5px;
  background: #0d111a;
  color: white;
  font-size: 1.2em;
  text-transform: uppercase;
  border: none;
  border-bottom: 1px solid white;
  margin-bottom: 20px;
  margin-top:50px;
  width: 50%;
  text-align: center;
}

#searchBarMobile:focus {
  outline: none;
  color: red;
}


/* Backdrop style */
.backdrop {
  height: 100%;
  width: 100%;
  background: #161a25;
  top: 0;
  left: 0;
  z-index: 200;
  position: fixed;
}

/* Media Query */
@media (max-width: 702px) {
  .nav-items {
    display: none;
  }

  #logo {
    height: 40px;
    width: auto;
  }
}

@media (min-width: 703px) {
  .nav-icon {
    display: none;
  }
}
