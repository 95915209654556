.searchBar{
    margin-top: 30px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#searchInput{
    width: 80%;
    height: 50px;
    background-color: #080C14;
    border: 1px solid #151b2a;
    font-size: 1em;
    padding-left: 30px;
    color: white;
}

#searchInput::placeholder { 
    color: white;
}

#searchInput:focus{
    background-color: #151b2a;
    border: 1px solid #151b2a;
    outline: none;
    color: white;
    padding-left: 30px
}

#searchButton{
    width: 20%;
    height: 50px;
    background-color: #151b2a;
    border: 1px solid #151b2a;
    font-size: 1em;
    color: white;
    cursor: pointer
    ;
}

#searchButton:hover{
    background-color:#080C14;
    border: 1px solid white;
    cursor: pointer;
}


#noResult{
    color: white;
    font-size: 1em;
    text-align: center;
}





/* GAME */


#choice{
    border: 1px solid white;
    width: 200px;
    height: 200px;
}

.choiceAllie{
    border: 1px solid white;
    width: 200px;
    height: 200px;
}

.choiceenemie{
    border: 1px solid white;
    width: 200px;
    height: 200px;
}
